<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title>Package Detail</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn to="/mailroom/admin/package" exact>Back to Package List</v-btn>
    </v-toolbar>
    <v-card-title>Recipient: {{ userName }} ({{ userType }})</v-card-title>
    <v-card-text>
      <v-row>
        <v-col :cols="12" md="6">
          <v-card>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Package Barcode</v-list-item-subtitle>
                  <v-list-item-title>{{ barcode }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Package Type</v-list-item-subtitle>
                  <v-list-item-title>{{ packageType }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-menu dense>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon>
                        <v-icon>fal fa-pencil</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item v-for="({ text }, index) in typeOptions" :key="'packageType-' + index" :disabled="packageType === text" @click="updatePackage({ packageType: text })">
                        <v-list-item-title>{{ text }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list-item-action>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Location</v-list-item-subtitle>
                  <v-list-item-title>{{ location }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-menu dense>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon>
                        <v-icon>fal fa-pencil</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item v-for="({ text }, index) in locationOptions" :key="'location-' + index" :disabled="location === text" @click="updatePackage({ location: text })">
                        <v-list-item-title>{{ text }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list-item-action>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>Status</v-list-item-subtitle>
                  <v-list-item-title>{{ status }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-menu dense>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon>
                        <v-icon>fal fa-pencil</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item v-for="({ text }, index) in statusOptions" :key="'status-' + index" :disabled="status === text" @click="updatePackage({ status: text })">
                        <v-list-item-title>{{ text }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <v-card-actions>
              <v-btn text to="/mailroom/admin/package">Done</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col :cols="12" md="6">
          <v-card>
            <v-tabs v-model="tab">
              <v-tab>
                <v-badge :content="history.length">Status History</v-badge>
              </v-tab>
              <v-tab>
                <v-badge v-if="notifications.length > 0" :content="notifications.length">Notifications</v-badge>
                <span v-else>Notifications</span>
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-list>
                  <v-list-item v-for="({ status, date, user }, index) in history" :key="'history-' + index">
                    <v-list-item-content>
                      <v-list-item-title>Status changed to: {{ status }}</v-list-item-title>
                      <v-list-item-subtitle>By: {{ user }}</v-list-item-subtitle>
                      <v-list-item-subtitle>On: {{ stringFormatDate(date) }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-tab-item>
              <v-tab-item>
                <v-list>
                  <v-dialog v-for="({ to, date, title, email, text }, index) in notifications" :key="'notification-' + index" width="600">
                    <template v-slot:activator="{ on }">
                      <v-list-item v-on="on">
                        <v-list-item-content>
                          <v-list-item-title>Notification Sent: {{ title }}</v-list-item-title>
                          <v-list-item-subtitle>To: {{ to.join(', ') }}</v-list-item-subtitle>
                          <v-list-item-subtitle>Sent: {{ stringFormatDate(date) }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <v-card>
                      <v-card-title>{{ title }}</v-card-title>
                      <v-card-text v-if="email && email.include && toEmails(to).length > 0" class=".v-text-field--outlined">
                        <fieldset style="padding: 0 12px; border-radius: 4px; border-width: 1px">
                          <legend style="font-size:12px">Email Message</legend>
                          <h4>Subject: {{ email.subject }}</h4>
                          <h4 style="margin-bottom:10px">Sent to: {{ toEmails(to).join('; ') }}</h4>
                          <p v-html="email.body"></p>
                        </fieldset>
                      </v-card-text>
                      <v-card-text v-if="text && text.include && toPhones(to).length > 0">
                        <fieldset style="padding: 0 12px; border-radius: 4px; border-width: 1px">
                          <legend style="font-size:12px">Text Message</legend>
                          <h4 style="margin-bottom:10px">Sent to: {{ toPhones(to).join('; ') }}</h4>
                          <p>{{ text.message }}</p>
                        </fieldset>
                      </v-card-text>
                      <v-card-text>Click outside the window to close</v-card-text>
                    </v-card>
                  </v-dialog>
                </v-list>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { computed, onMounted, ref } from '@vue/composition-api'
import { stringFormatDate } from '../../../../helpers/formatters'

export default {
  setup (props, { root }) {
    const id = computed(() => root.$route.params.id)
    const tab = ref(0)
    const barcode = ref('')
    const userId = ref('')
    const userName = ref('')
    const userType = ref('')
    const packageType = ref('')
    const location = ref('')
    const status = ref('')
    const history = ref([])
    const notifications = ref([])

    onMounted(() => {
      root.$feathers.service('mailroom/package').get(id.value).then(loadPackage)
    })

    async function loadPackage (data) {
      barcode.value = data.barcode
      userId.value = data.user.user
      userName.value = data.user.name
      userType.value = data.user.person
      packageType.value = data.packageType
      location.value = data.location
      status.value = data.status
      history.value = data.history
      notifications.value = data.notifications
      for (let i = 0; i < notifications.value.length; i++) {
        const notificationData = await root.$feathers.service('mailroom/notification').get(notifications.value[i].id)
        notificationData.to = notifications.value[i].to
        notificationData.date = notifications.value[i].date
        notifications.value.splice(i, 1, notificationData)
      }
    }

    const statusOptions = ref([])
    const locationOptions = ref([])
    const typeOptions = ref([])

    onMounted(() => {
      const optionService = root.$feathers.service('forms/select-options')
      optionService.find({ query: { name: 'Mailroom: Package Status' } }).then(({ data }) => {
        if (data.length > 0) {
          optionService.get(data[0].value).then(({ options }) => {
            statusOptions.value = options
          })
        }
      })
      optionService.find({ query: { name: 'Mailroom: Package Location' } }).then(({ data }) => {
        if (data.length > 0) {
          optionService.get(data[0].value).then(({ options }) => {
            locationOptions.value = options
          })
        }
      })
      optionService.find({ query: { name: 'Mailroom: Package Type' } }).then(({ data }) => {
        if (data.length > 0) {
          optionService.get(data[0].value).then(({ options }) => {
            typeOptions.value = options
          })
        }
      })
    })

    function updatePackage (obj) {
      root.$feathers.service('mailroom/package').patch(id.value, obj).catch((e) => {
        root.$store.dispatch('main/snackbar', { active: true, color: 'error', timeout: 10000, text: 'Error updating package: ' + e })
      }).then((data) => {
        loadPackage(data)
        root.$store.dispatch('main/snackbar', { active: true, color: 'success', timeout: 6000, text: 'Package updated successfully' })
      })
    }

    function toEmails (to) {
      const arr = []
      for (const item of to) {
        if (/.+@.+\..+/.test(item)) {
          arr.push(item)
        }
      }
      return arr
    }
    function toPhones (to) {
      const arr = []
      for (const item of to) {
        if (!/.+@.+\..+/.test(item)) {
          arr.push(item)
        }
      }
      return arr
    }

    return {
      id,
      tab,
      barcode,
      userId,
      userName,
      userType,
      packageType,
      location,
      status,
      history,
      notifications,
      statusOptions,
      locationOptions,
      typeOptions,
      updatePackage,
      toEmails,
      toPhones,
      stringFormatDate
    }
  }
}
</script>
