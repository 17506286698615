var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Package Detail")]),_c('v-spacer'),_c('v-btn',{attrs:{"to":"/mailroom/admin/package","exact":""}},[_vm._v("Back to Package List")])],1),_c('v-card-title',[_vm._v("Recipient: "+_vm._s(_vm.userName)+" ("+_vm._s(_vm.userType)+")")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":12,"md":"6"}},[_c('v-card',[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Package Barcode")]),_c('v-list-item-title',[_vm._v(_vm._s(_vm.barcode))])],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Package Type")]),_c('v-list-item-title',[_vm._v(_vm._s(_vm.packageType))])],1),_c('v-list-item-action',[_c('v-menu',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("fal fa-pencil")])],1)]}}])},[_c('v-list',_vm._l((_vm.typeOptions),function(ref,index){
var text = ref.text;
return _c('v-list-item',{key:'packageType-' + index,attrs:{"disabled":_vm.packageType === text},on:{"click":function($event){return _vm.updatePackage({ packageType: text })}}},[_c('v-list-item-title',[_vm._v(_vm._s(text))])],1)}),1)],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Location")]),_c('v-list-item-title',[_vm._v(_vm._s(_vm.location))])],1),_c('v-list-item-action',[_c('v-menu',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("fal fa-pencil")])],1)]}}])},[_c('v-list',_vm._l((_vm.locationOptions),function(ref,index){
var text = ref.text;
return _c('v-list-item',{key:'location-' + index,attrs:{"disabled":_vm.location === text},on:{"click":function($event){return _vm.updatePackage({ location: text })}}},[_c('v-list-item-title',[_vm._v(_vm._s(text))])],1)}),1)],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Status")]),_c('v-list-item-title',[_vm._v(_vm._s(_vm.status))])],1),_c('v-list-item-action',[_c('v-menu',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("fal fa-pencil")])],1)]}}])},[_c('v-list',_vm._l((_vm.statusOptions),function(ref,index){
var text = ref.text;
return _c('v-list-item',{key:'status-' + index,attrs:{"disabled":_vm.status === text},on:{"click":function($event){return _vm.updatePackage({ status: text })}}},[_c('v-list-item-title',[_vm._v(_vm._s(text))])],1)}),1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","to":"/mailroom/admin/package"}},[_vm._v("Done")])],1)],1)],1),_c('v-col',{attrs:{"cols":12,"md":"6"}},[_c('v-card',[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_c('v-badge',{attrs:{"content":_vm.history.length}},[_vm._v("Status History")])],1),_c('v-tab',[(_vm.notifications.length > 0)?_c('v-badge',{attrs:{"content":_vm.notifications.length}},[_vm._v("Notifications")]):_c('span',[_vm._v("Notifications")])],1)],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-list',_vm._l((_vm.history),function(ref,index){
var status = ref.status;
var date = ref.date;
var user = ref.user;
return _c('v-list-item',{key:'history-' + index},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Status changed to: "+_vm._s(status))]),_c('v-list-item-subtitle',[_vm._v("By: "+_vm._s(user))]),_c('v-list-item-subtitle',[_vm._v("On: "+_vm._s(_vm.stringFormatDate(date)))])],1)],1)}),1)],1),_c('v-tab-item',[_c('v-list',_vm._l((_vm.notifications),function(ref,index){
var to = ref.to;
var date = ref.date;
var title = ref.title;
var email = ref.email;
var text = ref.text;
return _c('v-dialog',{key:'notification-' + index,attrs:{"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Notification Sent: "+_vm._s(title))]),_c('v-list-item-subtitle',[_vm._v("To: "+_vm._s(to.join(', ')))]),_c('v-list-item-subtitle',[_vm._v("Sent: "+_vm._s(_vm.stringFormatDate(date)))])],1)],1)]}}],null,true)},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(title))]),(email && email.include && _vm.toEmails(to).length > 0)?_c('v-card-text',{staticClass:".v-text-field--outlined"},[_c('fieldset',{staticStyle:{"padding":"0 12px","border-radius":"4px","border-width":"1px"}},[_c('legend',{staticStyle:{"font-size":"12px"}},[_vm._v("Email Message")]),_c('h4',[_vm._v("Subject: "+_vm._s(email.subject))]),_c('h4',{staticStyle:{"margin-bottom":"10px"}},[_vm._v("Sent to: "+_vm._s(_vm.toEmails(to).join('; ')))]),_c('p',{domProps:{"innerHTML":_vm._s(email.body)}})])]):_vm._e(),(text && text.include && _vm.toPhones(to).length > 0)?_c('v-card-text',[_c('fieldset',{staticStyle:{"padding":"0 12px","border-radius":"4px","border-width":"1px"}},[_c('legend',{staticStyle:{"font-size":"12px"}},[_vm._v("Text Message")]),_c('h4',{staticStyle:{"margin-bottom":"10px"}},[_vm._v("Sent to: "+_vm._s(_vm.toPhones(to).join('; ')))]),_c('p',[_vm._v(_vm._s(text.message))])])]):_vm._e(),_c('v-card-text',[_vm._v("Click outside the window to close")])],1)],1)}),1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }